module.exports = [{
      plugin: require('../../../shared/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Professional aussiito website","description":"Personnaliser votre site en ligne","short_name":"mini site","lang":"fr","start_url":"/","display":"minimal-ui","icon":"src/images/favicon-aussiito.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0ca05c823d7852b72f1aac2c471fabe7"},
    },{
      plugin: require('../../../shared/node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(min-width: 576px)","sm":"(min-width: 768px)","md":"(min-width: 992px)","l":"(min-width: 1200px)"}},
    },{
      plugin: require('../../../shared/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
